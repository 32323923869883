import { GROUP as PASSENGER_TYPE_GROUP } from 'state/checkoutPage/travelers/constants/passengerTypes';
import getTravelerForBookingAndPayments from 'state/checkoutPage/travelers/selectors/getTravelerForBookingAndPayments';

export default function getFlightsProductForBookingAndPayments(
  searchToken,
  selectedFlights,
  selectedFlightsExtras,
  travelers,
  numberOfAdults,
  childrenAges,
  frequentFlyer,
  travelDocumentRequired,
  travelDocumentResidenceCountryRequired,
  travelDocumentNationalityRequired,
  travelDocumentIssuedRequired,
  travelDocumentIssuingCityRequired
) {
  const passengersIndexTracking = {};
  return [
    {
      searchToken,
      productToken: selectedFlights.toString(),
      upgrades:
        travelers && travelers.length > 0
          ? selectedFlightsExtras[PASSENGER_TYPE_GROUP]
          : Object.values(selectedFlightsExtras) // For payment options we send all upgrades in a single array
              .map((item) => Object.values(item).flat())
              .flat(),
      passengers:
        travelers && travelers.length > 0
          ? Object.values(travelers.map((room) => Object.values(room).flat()))
              .flat()
              .map((traveler, index) => {
                passengersIndexTracking[traveler.passengerType] =
                  passengersIndexTracking[traveler.passengerType] !== undefined
                    ? passengersIndexTracking[traveler.passengerType] + 1
                    : 0;
                const upgrades =
                  selectedFlightsExtras[traveler.passengerType][passengersIndexTracking[traveler.passengerType]];

                return getTravelerForBookingAndPayments(
                  traveler,
                  upgrades,
                  frequentFlyer,
                  index,
                  travelDocumentRequired,
                  travelDocumentResidenceCountryRequired,
                  travelDocumentNationalityRequired,
                  travelDocumentIssuedRequired,
                  travelDocumentIssuingCityRequired
                );
              })
          : null,
      adults: numberOfAdults,
      childAges: childrenAges,
    },
  ];
}
