import { css } from 'styled-components';
import { mediaQueries } from 'styles/utils/mediaQueries';

export default css`
  .custom-modal {
    &.ReactModal__Overlay {
      height: 100vh;
      position: fixed;
      z-index: 999;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      background-color: rgba(0, 0, 0, 0.7);
      opacity: 0;
      transition: opacity 0.3s ease-in-out;

      &--after-open {
        opacity: 1;
      }

      &--before-close {
        opacity: 0;
      }
    }

    .ReactModal__Content {
      max-height: 100vh;
      position: fixed;
      top: 0;
      right: 0;
      width: 100%;
      overflow: auto;
      outline: none;
      transition: top 0.3s ease-out;

      ${mediaQueries.fromAndAbove.desktop`
        top: 50%;
        right: 50%;
        width: auto;
        border-radius: 4px;
        transform: translate(50%, -50%);
        max-height: 90vh;
      `}
    }
  }
`;
