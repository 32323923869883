import React, { Component } from 'react';
import NotFound from './notFound';
import getCookie from 'utils/cookie/getCookie';
import { CLARITY_USER_ID } from 'constants/cookiesKeys';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidMount() {
    try {
      const clarityId = getCookie(CLARITY_USER_ID);
      if (window.Rollbar && clarityId) {
        // Configure Rollbar to include clarity id
        window.Rollbar.configure({
          payload: {
            custom: {
              userSession: clarityId,
            },
          },
        });
      }
    } catch (error) {
      window.Rollbar.error(`Error on configuring Rollbar - ${document.referrer}`, error);
      console.error(`Error on configuring Rollbar - ${document.referrer}`, error);
    }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    window.Rollbar.error(error);
  }

  render() {
    if (this.state.hasError) {
      return <NotFound />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
