export default function getTravelerForBookingAndPayments(
  {
    title,
    gender,
    firstName,
    lastName,
    dateOfBirth,
    rewardNumber,
    travelDocumentType,
    travelDocumentNumber,
    travelDocumentCountryCode,
    travelDocumentNationality,
    travelDocumentExpiration,
    travelDocumentResidenceCountry,
    travelDocumentIssued,
    travelDocumentIssuingCity,
  },
  upgrades = [],
  frequentFlyer,
  travelerIndex,
  travelDocumentRequired,
  travelDocumentResidenceCountryRequired,
  travelDocumentNationalityRequired,
  travelDocumentIssuedRequired,
  travelDocumentIssuingCityRequired
) {
  let _frequentFlyer = null;

  if (frequentFlyer) {
    const { frequentFlyerType, rewardNumber: _rewardNumber, data } = frequentFlyer;
    _frequentFlyer = {
      frequentFlyerType,
      rewardNumber: _rewardNumber,
    };

    if (data && data.isAirBaltic && rewardNumber && rewardNumber.value) {
      _frequentFlyer.rewardNumber = rewardNumber.value;
    } else if (data && data.isNorwegian && rewardNumber && rewardNumber.value && travelerIndex === 0) {
      _frequentFlyer.rewardNumber = rewardNumber.value;
    } else {
      _frequentFlyer = null;
    }
  }

  let travelDocument = null;
  if (travelDocumentRequired) {
    travelDocument = {
      countryCode: travelDocumentCountryCode.value ? travelDocumentCountryCode.value.value : null,
      number: travelDocumentNumber.value,
      expiration: travelDocumentExpiration.value ? travelDocumentExpiration.value.format('YYYY-MM-DD') : null,
      type: travelDocumentType.value ? Number(travelDocumentType.value.value) : null,
      dateOfBirth: dateOfBirth.value.format('YYYY-MM-DD'),
    };
  }

  if (travelDocumentResidenceCountryRequired) {
    travelDocument = {
      ...travelDocument,
      dateOfBirth: dateOfBirth.value.format('YYYY-MM-DD'),
      residenceCountry: travelDocumentResidenceCountry.value.value,
    };
  }

  if (travelDocumentNationalityRequired) {
    travelDocument = {
      ...travelDocument,
      dateOfBirth: dateOfBirth.value.format('YYYY-MM-DD'),
      nationality: travelDocumentNationality.value ? travelDocumentNationality.value.value : null,
    };
  }

  if (travelDocumentIssuedRequired) {
    travelDocument = {
      ...travelDocument,
      dateOfBirth: dateOfBirth.value.format('YYYY-MM-DD'),
      issued: travelDocumentIssued.value ? travelDocumentIssued.value.format('YYYY-MM-DD') : null,
    };
  }

  if (travelDocumentIssuingCityRequired) {
    travelDocument = {
      ...travelDocument,
      dateOfBirth: dateOfBirth.value.format('YYYY-MM-DD'),
      city: travelDocumentIssuingCity ? travelDocumentIssuingCity.value : null,
    };
  }

  return {
    title: title.value,
    firstName: !!firstName.value ? firstName.value.trim() : firstName.value,
    lastName: !!lastName.value ? lastName.value.trim() : lastName.value,
    gender: gender.value.value,
    dateOfBirth: dateOfBirth.value.format('YYYY-MM-DD'),
    travelDocument,
    upgrades,
    frequentFlyer: _frequentFlyer,
  };
}
