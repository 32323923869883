import moment from 'moment';
import * as inputNames from '../constants/inputNames';
import * as passengerTypes from '../constants/passengerTypes';

export default function getTraveler(mock, passengerType, passengerIndex, roomIndex, mockData, additionalData = {}) {
  const rndCharRoom = ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i'];
  const rndCharPassenger = ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i'];

  const validity = mock ? { valid: true, message: null } : null;
  const title = mock ? 'Mr.' : '';
  const firstName = mock ? `${passengerType} Name ${rndCharRoom[roomIndex]}${rndCharPassenger[passengerIndex]}` : '';
  const lastName = mock ? `${passengerType} Surname ${rndCharRoom[roomIndex]}${rndCharPassenger[passengerIndex]}` : '';
  const gender = mock
    ? {
        value: 'male',
        data: { message: { id: 'gender.male.label', description: 'Male', defaultMessage: 'Male' } },
        dataAttr: { roomIndex, index: passengerIndex, passengerType },
      }
    : '';
  const dateString =
    passengerType === passengerTypes.ADULT
      ? '1990-05-26'
      : passengerType === passengerTypes.CHILD
      ? '2010-05-26'
      : '2019-12-26'; // @TODO: Dates for children should be generated based on current date.
  const dateOfBirth = !mock
    ? ''
    : mockData && mockData[inputNames.DATE_OF_BIRTH]
    ? mockData[inputNames.DATE_OF_BIRTH]
    : moment(dateString);
  const rewardNumber = passengerType === passengerTypes.ADULT ? '' : null;
  const rewardNumberDisabled = passengerType !== passengerTypes.ADULT;

  let travelerValues = {
    [inputNames.TITLE]: { value: title, validity, disabled: false },
    [inputNames.FIRST_NAME]: { value: firstName, validity, disabled: false },
    [inputNames.LAST_NAME]: { value: lastName, validity, disabled: false },
    [inputNames.GENDER]: { value: gender, validity, disabled: false },
    [inputNames.DATE_OF_BIRTH]: { value: dateOfBirth, validity, disabled: false },
    [inputNames.REWARD_NUMBER]: { value: rewardNumber, validity, disabled: rewardNumberDisabled },

    passengerType,
  };

  if (additionalData.travelDocumentRequired) {
    travelerValues = {
      ...travelerValues,
      [inputNames.TRAVEL_DOCUMENT_TYPE]: { value: null, validity, disabled: false },
      [inputNames.TRAVEL_DOCUMENT_NUMBER]: { value: null, validity, disabled: false },
      [inputNames.TRAVEL_DOCUMENT_COUNTRY_CODE]: { value: null, validity, disabled: false },
      [inputNames.TRAVEL_DOCUMENT_EXPIRATION]: { value: null, validity, disabled: false },
    };
  }

  if (additionalData.travelDocumentResidenceCountryRequired) {
    travelerValues = {
      ...travelerValues,
      [inputNames.TRAVEL_DOCUMENT_RESIDENCE_COUNTRY]: { value: null, validity, disabled: false },
    };
  }

  if (additionalData.travelDocumentNationalityRequired) {
    travelerValues = {
      ...travelerValues,
      [inputNames.TRAVEL_DOCUMENT_NATIONALITY]: { value: null, validity, disabled: false },
    };
  }

  if (additionalData.travelDocumentIssuedRequired) {
    travelerValues = {
      ...travelerValues,
      [inputNames.TRAVEL_DOCUMENT_ISSUED]: { value: null, validity, disabled: false },
    };
  }

  if (additionalData.travelDocumentIssuingCityRequired) {
    travelerValues = {
      ...travelerValues,
      [inputNames.TRAVEL_DOCUMENT_ISSUING_CITY]: { value: null, validity, disabled: false },
    };
  }

  return travelerValues;
}
