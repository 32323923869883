import getTravelerForBookingAndPayments from 'state/checkoutPage/travelers/selectors/getTravelerForBookingAndPayments';

export default function getHotelsProductForBookingAndPayments(
  searchToken,
  selectedHotels,
  selectedRooms = [],
  travelers,
  commentToHotel,
  travelDocumentRequired,
  travelDocumentResidenceCountryRequired,
  travelDocumentNationalityRequired,
  travelDocumentIssuedRequired,
  travelDocumentIssuingCityRequired
) {
  const selectedRoomsToRooms = getSelectedRoomsToRoomsCallback(
    travelers,
    travelDocumentRequired,
    travelDocumentResidenceCountryRequired,
    travelDocumentNationalityRequired,
    travelDocumentIssuedRequired,
    travelDocumentIssuingCityRequired
  );
  return selectedHotels.map((selectedHotel) => {
    return {
      searchToken,
      commentToHotel,
      rooms: selectedRooms
        .filter(({ hotelReferenceToken }) => hotelReferenceToken === selectedHotel)
        .map(selectedRoomsToRooms),
    };
  });
}

function getSelectedRoomsToRoomsCallback(
  travelers,
  travelDocumentRequired,
  travelDocumentResidenceCountryRequired,
  travelDocumentNationalityRequired,
  travelDocumentIssuedRequired,
  travelDocumentIssuingCityRequired
) {
  return function selectedRoomsToRooms(selectedRoom, index) {
    const { selectedOption, adults, childages: childAges } = selectedRoom;
    const { productToken } = selectedOption;
    const guests =
      travelers && travelers.length > 0
        ? Object.values(travelers[index])
            .flat()
            .map((traveler) =>
              getTravelerForBookingAndPayments(
                traveler,
                undefined,
                undefined,
                undefined,
                travelDocumentRequired,
                travelDocumentResidenceCountryRequired,
                travelDocumentNationalityRequired,
                travelDocumentIssuedRequired,
                travelDocumentIssuingCityRequired
              )
            )
        : null;

    return {
      productToken,
      guests,
      adults,
      childAges,
    };
  };
}
